import React, {useEffect, useRef, createRef, useState, useLayoutEffect} from "react";
import {useSetState} from "react-use";

import { gsap } from "gsap";

import {Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useSwipeable} from "react-swipeable";

const Player = React.forwardRef((props,ref) => {
    return(
        <div id={"player"} ref={ref}></div>
    )
})

const StatusMessage = (props) => {

    const {point, display, message} = props

    return(
        <div id={"statusmessage"}>
            {display &&
                <>
                    <div className={`message message_${message}`}/>
                    <div className={`point point_${point}`}/>
                </>
            }
        </div>
    )
}

const GameUi = (props) => {

    const {points, timer, combo} = props;

    return(
        <div id={"game_ui"}>
            <div className={"score"}>{points} point{points>1 && "s"}</div>
            <div className={"timer"}>
                <div className={"timer_body"}/>
                {timer}&nbsp;&nbsp;s
            </div>
            <div className={"combo"}>{combo}</div>
        </div>
    )
}

const GameKeyBar = (props) => {

    let keyNice = ""

    return(
        <div id={"key_sequence"}>
            {props.keys.map((key,index) => {

               switch(key.key){
                   case 37 : keyNice = "left"; break;
                   case 38 : keyNice = "top"; break;
                   case 39 : keyNice = "right"; break;
                   case 40 : keyNice = "bottom"; break;
               }

                return( <span><div className={`${keyNice}`} state={key.state} /></span> )
            })}
        </div>
    )
}

const SwimmingGame = () => {

    //state
    const [gameState, setGameState] = useSetState({
        isRunning : false,
        timer : 60,
        combo : 0,
        points : 0,
        pointStep : 1,
        keyPress : null,
        keySequence : [],
        keySequenceIndex : 0,
        keySequenceCapture : false,
        winAnimationTlRef : []
    })

    const [messageState,setMessageState] = useState({
        win : false,
        point : 0,
        display : false,
        message : ""
    })

    //Modal state
    const [showStartModal, setShowStartModal] = useState(false);
    const [showEndModal, setShowEndModal] = useState(false);

    //ref
    const playerRef = useRef();
    const timerRef = useRef();

    const messageTimerRef = useRef();

    //timeline ref
    const gameRef = useRef();
    const startTlRef = useRef();
    const endTlRef = useRef();
    const movingSkyTlRef = useRef();
    const loseTlRef = useRef();

    //win animation param
    const winAnimationFrame = [10,10,9,10,9]

    //game key left top right back
    const gameKey = [37,38,39,40]

    //handle mobile swipe
    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            //console.log("User Swiped!", eventData)
            let key

            switch(eventData.dir){
                case "Up" : key=38; break;
                case "Down" : key=40; break;
                case "Right" : key=39; break;
                case "Left" : key=37; break;
            }

            captureKeySequence({which : key})

        },
        delta: 50,                             // min distance(px) before a swipe starts. *See Notes*
        preventScrollOnSwipe: true,           // prevents scroll during swipe (*See Details*)
        trackTouch: true,                      // track touch input
        trackMouse: false,                     // track mouse input
        rotationAngle: 0,                      // set a rotation angle
        swipeDuration: Infinity,               // allowable duration of a swipe (ms). *See Notes*
        touchEventOptions: { passive: true },  // options for touch listeners (*See Details*)
    });

    const toggleFullScreen = (state) => {

        if ("ontouchstart" in document.documentElement){
            let elem = document.getElementById("fullscreenHandler")

            if(state){
                if (document.exitFullscreen) {
                    elem.requestFullscreen()
                }else{
                    document.querySelector("body").style.overflow = 'hidden'
                    document.querySelector("body").style.touchAction = 'none'
                }

            }else{

                if (document.exitFullscreen) {
                    document.exitFullscreen().catch((err) => console.error(err));
                }
                else{
                    document.querySelector("body").style.overflow = 'auto'
                    document.querySelector("body").style.touchAction = 'all'
                }

            }
        }

    }

    //gsap timeline for animation
    useLayoutEffect(() => {

        //create context to easily destroy them on unmount
        const ctx = gsap.context(() => {

            //game start animation
            startTlRef.current = gsap
                .timeline({
                    /*repeat:-1,*/
                    paused:true
                })
                .addLabel("start",0)
                .to("#player",{
                    duration : 2,
                    bottom : "50%",
                    onStart : () => document.getElementById("player").className = "flying"
                },"start")
                .set(gameRef.current,{
                    backgroundPositionY : "100%"
                })
                .to(gameRef.current,
                    {
                        backgroundPositionY: "0%",
                        duration: 2,
                        //onComplete : () => document.getElementById("player").classList.remove("flying")
                    },
                    "start"
                )

            movingSkyTlRef.current = gsap
                .timeline({
                    paused:true
                })
                .set(gameRef.current,{
                    backgroundPositionY : "0%"
                })
                .to(gameRef.current,
                    {
                        backgroundPositionY: "100%",
                        duration: gameState.timer,
                        ease: "linear"
                    }
                )

            //win animation
            winAnimationFrame.forEach((item, index) => {
                let animRef = createRef()

                //console.log(index,item)
                animRef.current = gsap
                    .timeline({
                        /*repeat : -1,*/
                        paused:true,
                    })
                    .set("#player",{
                        backgroundPosition: "0% 50%"
                    })
                    .to("#player",
                        {
                        onStart : () => document.getElementById("player").className = `win${index+1}`,
                        duration : 0.1 * parseInt(item),
                        backgroundPosition: "100% 50%",
                        ease: "steps("+(item-1)+")"
                    })

                setGameState((prevState) => {
                    return({
                        winAnimationTlRef : [...prevState.winAnimationTlRef, animRef]
                    })
                })
            })

            //lose animation
            loseTlRef.current = gsap
                .timeline({
                    /*repeat : -1,*/
                    paused:true
                })
                .set("#player",{
                    backgroundPosition: "0% 50%"
                })
                .to("#player",{
                    onStart : () => document.getElementById("player").className = `lose`,
                    duration : 0.5,
                    backgroundPosition: "100% 50%",
                    ease: "steps(3)"
                })

            //game end animation
            //game start animation
            endTlRef.current = gsap
                .timeline({
                    /*repeat:-1,*/
                    paused:true
                })
                .to("#player",{
                    duration : 2,
                    bottom : "-100%",
                    onStart : () => document.getElementById("player").className = "falling",
                    /*OnComplete : () => {
                        setShowEndModal(true)
                    }*/
                })


        }, gameRef.current)

        return () => ctx.revert()

    }, [])

    //add point to the player and change combo value
    const setPlayerPoint = () => {

        //play a random animation
        const animationIndex = Math.round(Math.random() * (gameState.winAnimationTlRef.length-1));
        gameState.winAnimationTlRef[animationIndex].current.restart()

        setGameState((prevState) => {

            handleMessageDisplay(true, prevState.pointStep)

            return({
                combo : prevState.combo+1,
                points : prevState.points + prevState.pointStep
            })
        })
    }

    //handlePlayerLose
    const setPlayerLose = () => {
        //lose animation
        loseTlRef.current.restart()
        setGameState((prevState) => {

            if(prevState.combo === 0)
                createRandomKeySequence()

            handleMessageDisplay(false, 0)

            return({combo : 0 })
        })
    }

    //record user key
    const captureKeySequence = (e) => {

        if (e.target)
            e.preventDefault()

        if(!gameState.keySequenceCapture)
            return;

        const currentKey = e.which
        const keySeq = gameState.keySequence
        let keySeqIndex = gameState.keySequenceIndex
        const keySequenceIsOver = (keySeqIndex+1) === keySeq.length

        //console.log(keySequenceIsOver)

        if(currentKey === keySeq[keySeqIndex].key){

            //good key
            keySeq[keySeqIndex].state = 1

            //console.log((keySeqIndex+1),keySeq.length)

            //check end of the key sequence
            if( !keySequenceIsOver ) {
                keySeqIndex = keySeqIndex + 1
            }else{
                //addplayerpoint
                setPlayerPoint()
            }

            //save state
            setGameState({
                keySequenceIndex : keySeqIndex,
                keySequence : keySeq,
                keySequenceCapture : !keySequenceIsOver
            })

        }
        else{

            //bad key
            keySeq[keySeqIndex].state = 2

            setPlayerLose()

            //save state
            setGameState((prevState) => {
                return {
                    keySequenceIndex : prevState.keySequenceIndex+1,
                    keySequence : keySeq,
                    keySequenceCapture : false
                }
            })

        }

    }

    //generate random key and reset key index
    const createRandomKeySequence = () => {

        let keyCount = 4
        let pointStep = 1

        console.log(gameState.combo)

        if(gameState.combo >= 2 && gameState.combo < 4){
            keyCount = 5
            pointStep = 2
        }
        else if(gameState.combo >= 4 && gameState.combo < 6){
            keyCount = 6
            pointStep = 3
        }
        else if(gameState.combo >= 6 && gameState.combo < 8){
            keyCount = 7
            pointStep = 4
        }
        else if( gameState.combo >= 8 ){
            keyCount = 8
            pointStep = 6
        }

        let randomKey = []

        for(let i=0 ; i < keyCount ; i++ ){
            randomKey.push(
                {
                    key : gameKey[Math.floor(Math.random()*gameKey.length)],
                    state : 0 // 0 - pending / 1 - good / 2 - bad
                }
            )
        }

        setGameState((prevState) => {
            return(
                {
                    keySequence : randomKey,
                    keySequenceIndex : 0,
                    keySequenceCapture : true,
                    pointStep : pointStep
                }
            )
        })
    }

    //handleMessageDisplay
    const handleMessageDisplay = (win, point) => {

        let message = ""

        if(win){
            //select random message
            const messageId = Math.round(Math.random() * 3) + 1
            message = "win"+messageId
        }else{
            message = "lose"
        }

        setMessageState({
            message : message,
            point : point,
            display: true
        })

        if(messageTimerRef.current)
            clearTimeout(messageTimerRef.current)

        //TODO add ref to timer to reset between message
        //set timeout to hide message
        messageTimerRef.current = setTimeout(() => {
            setMessageState({
                display: false
            })
        },2500)

    }

    //start game
    const startGame = () => {

        //setfullScreen
        toggleFullScreen(true)

        setShowStartModal(false)
        setGameState({
            isRunning : false,
            timer : 60,
            combo : 0,
            points : 0,
            pointStep : 1,
            keyPress : null,
            keySequence : [],
            keySequenceIndex : 0,
            keySequenceCapture : false
        })

        //reset all animation
        startTlRef.current.seek(0).pause()
        endTlRef.current.seek(0).pause()
        loseTlRef.current.seek(0).pause()

        //reset player position and play start animation
        document.querySelector("#player").classList = ""
        document.querySelector("#player").style = ""

        //start countdown
        document.querySelector("#startCountDown").classList = "start1"
        let startId = 1

        const timerStart = setInterval(() => {
            startId++

            if(startId > 4){
                clearInterval(timerStart)
                document.querySelector("#startCountDown").classList = ""
                startTlRef.current.restart().eventCallback("onComplete", () => {

                    //start sky animation
                    movingSkyTlRef.current.restart()

                    //start timer
                    timerRef.current = setInterval(() => {
                        setGameState((prevState) => {

                            //game ending
                            if(prevState.timer === 1){

                                endTlRef.current.restart().eventCallback("onComplete", () => {
                                    setShowEndModal(true)
                                })

                                clearInterval(timerRef.current)
                                toggleFullScreen(false)

                                return({
                                    isRunning : false,
                                    keySequenceCapture : false,
                                    keySequence : [],
                                    timer : 0
                                })

                            }else{
                                return({
                                    timer : prevState.timer-1
                                })
                            }
                        })
                    },1000)
                    document.querySelector(".timer").classList.add("start")

                    setGameState({
                        isRunning : true
                    })

                    createRandomKeySequence()
                })
            }
            else
                document.querySelector("#startCountDown").classList = "start"+startId

        },1000);
    }

    //reset game
    const resetGame = () => {

        setGameState({
            combo : 0,
        })

        document.querySelector(".timer").classList.remove("start")

        setShowEndModal(false);
        setShowStartModal(true);
    }

    //set up key event
    useEffect(() => {
        document.addEventListener('keydown', captureKeySequence);
        return () => document.removeEventListener('keydown',captureKeySequence);
    })

    //handle player lose or gain points
    useEffect(() => {
        if(gameState.isRunning)
            createRandomKeySequence()
    },[gameState.combo, gameState.point])

    useEffect(() => {
        //startGame()
        setShowStartModal(true)
    },[])

    return(
        <div id={"fullscreenHandler"}>
            <Modal show={showStartModal} onHide={() => setShowStartModal(false)} id={"modalStartGame_plongeon"} centered={true} backdrop={"static"}>
                <Modal.Body>
                    <div className={"title"}>le plongeon artistique</div>
                    <div className={"description"}>

                        Faites le plus beau des plongeons !<br/>

                        <span className={"d-lg-none"}>
                            Enchaînez les figures et marquez un <br className={"d-lg-none"}/>
                            maximum de points en reproduisant les <br className={"d-lg-none"}/>
                            combinaisons qui apparaissent à l’écran.
                        </span>

                        <span className={"d-lg-inline d-none"}>
                            Enchaînez les figures et marquez un maximum de points en suivant les combinaisons<br/>
                            qui apparaissent à l’écran à l’aide des touches directionnelles de votre clavier.
                        </span>
                    </div>
                    <a href={"#0"} className={"cta orange picto"} onClick={startGame}>C'est parti !</a>
                    <div className={"mention"}>Jeu gratuit ne donnant droit à aucune dotation.</div>
                </Modal.Body>
            </Modal>
            <Modal show={showEndModal} onHide={() => setShowEndModal(false)} id={"modalEndGame_plongeon"} centered={true} backdrop={"static"}>
                <Modal.Body>
                    <div className={"score"}>{gameState.points}<span>{gameState.points > 1 ? "PTS":"PT"}</span></div>
                    <a href={"#0"} id={"cta_reset"} className={"cta orange"} onClick={resetGame}>rejouer</a>
                    <Link to={"/academie-du-gout"} id={"cta_offer"} className={"cta orange picto"} onClick={() => window.mixpanelhandler.track("Offer button click",{"Result count" : 1, "Game Name" : "Artistic diving" })}>découvrir l’offre</Link>
                </Modal.Body>
            </Modal>

            <div className={"mobile_landscape_switcher"}/>
            <div id={"game_swimming_wrapper"} className={"game_wrapper"} ref={gameRef}>
                <div id={"mobileSwipe"} {...handlers}/>

                <div id={"startCountDown"}/>

                <GameUi timer={gameState.timer} points={gameState.points} combo={gameState.combo}/>
                <GameKeyBar keys={gameState.keySequence}/>
                <StatusMessage  {...messageState}/>
                <Player animation={gameState.playerAnimation} ref={playerRef}/>

            </div>
        </div>
    )
}

export default SwimmingGame