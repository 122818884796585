import React from 'react';

import Contact from "./contact";
import PageTitle from "./_title";
import {Link} from "react-router-dom";

const FinOpe = () => {

	return(
		<>
			<PageTitle/>
			<div id="strate1" className={"strate"}>
				<p>Nous espérons que vous vous êtes régalés <br className={"d-lg-none"}/>avec <br className={"d-lg-block d-none"}/>toutes ces nouvelles recettes !</p>

				<div className={"finope_good"}>
					<span>Bonne nouvelle !</span>
					Vous pouvez encore jouer <br className={"d-lg-none"}/>aux jeux La Baleine !
				</div>

				<div className={"text-center"}>
					<Link to={"/#homeStrate3"} className={"cta white picto"}>
						jouer aux jeux
					</Link>
				</div>

				<div className={"encart"}>
					Retrouvez nos actualités sur <br className={"d-lg-none"}/>
					<a href={"https://www.labaleine.fr"} target={"_blank"}>www.labaleine.fr</a> <br/>
					et n’hésitez pas à nous suivre sur <br className={"d-lg-none"}/>
					nos réseaux sociaux !
				</div>

			</div>

			<Contact/>
		</>
	);
}
export default FinOpe;
