import React from 'react';
import {HashLink as Link} from 'react-router-hash-link';

const Footer = () => {

  return (
    <footer>

      <div id={"footer_wrapper"}>

        <div className={"footer_menu_wrapper"}>
          <div className={"footer_menu_first"}>
            <div>
              <div className={"footer_logo_baleine"}>
                <a href={"https://www.labaleine.fr/"} target={"_blank"}/>
                <a href={"https://www.labaleine.fr/la-baleine-a-90-ans/?utm_source=referral&utm_medium=banner&utm_campaign=tlc"} target={"_blank"}/>
              </div>
              <p>La Baleine, spécialiste du sel de mer depuis 1934</p>
            </div>
            <div>
              <nav>
                <Link to="/faq">FAQ</Link>
                <Link to="/#contactStrate1" smooth>Contact</Link>

                <a href="/file/Termes%20et%20conditions.pdf" target={"_blank"}>Termes et conditions</a>
                <a href="/file/Politique%20de%20confidentialité.pdf" target={"_blank"}>Politique de Confidentialité</a>
                <a href="/file/Politique%20de%20cookies.pdf" target={"_blank"}>Politique de Cookies</a>

                <Link to="/informations-legales">Informations Légales</Link>
              </nav>
            </div>
          </div>
          <div className={"footer_menu_last"}>
            Suivez-nous sur
            <a target={"_blank"} href={"https://www.instagram.com/labaleinefr/"}>Instagram</a>
            <a target={"_blank"} href={"https://www.facebook.com/Le.Sel.La.Baleine"}>Facebook</a>
            <a target={"_blank"} href={"https://www.youtube.com/channel/UCmMPQ1URDcyX58qjxTLoJ8w"}>Youtube</a>
          </div>
        </div>

        <div className={"footer_logo_wrapper"}>
          <div className={"footer_logo"}>
            <a id={"footer_logo_partenaire"} href={"https://www.academiedugout.fr"} target={"_blank"}/>
            <a id={"footer_logo_tlc"} href={"https://www.tlcmarketing.com/france"} target={"_blank"}/>
          </div>
        </div>

      </div>
    </footer>
  );
};

export default Footer;
