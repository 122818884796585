import React, {useState, useRef, useEffect} from 'react';

import {Form} from 'react-bootstrap';
import {useForm} from 'react-hook-form';

import CustomFieldText from '../form/CustomFieldText';
import CustomComboBox from "../form/CustomComboBox";
import BirthDayField from '../form/BirthDayField';

import {GameCarousel} from './home';

import * as Helper from '../form/form_inscription_helper.jsx';
import * as Pattern from '../form/form_pattern_helper.jsx';

import ReCAPTCHA from "react-google-recaptcha";
import Contact from "./contact";
import PageTitle from "./_title";
import CustomUploadField from "../form/CustomUploadField";


const JeParticipeForm = (props) => {

    const [file, setFile] = useState("")

    const {register, handleSubmit, formState: { errors }, setValue, clearErrors, setError } = useForm({validateCriteriaMode: "all"})
    const recaptchaRef = useRef(null)
    const submitRef = useRef(null)

    function setFilename(event,fieldname,setFileHandle) {

        if (event.target.files[0] !== undefined && Helper.checkIsUploadable(event.target.files[0])) {
            setValue(fieldname, event.target.files[0].name);
            setFileHandle(event.target.files[0]);
            clearErrors(fieldname);
        } else if (!Helper.checkIsUploadable(event.target.files[0])) {
            setError(fieldname, { type : "filesize", message : Helper.InvalidErrorMessages.filesize});
        }
    }

    //form validation
    const onSubmit = data => {

        //console.log(data)

        submitRef.current.setAttribute("disabled", "disabled");

        //controle recaptcha
        document.getElementById("recaptcha_error").innerHTML = " ";
        const recaptcha = recaptchaRef.current.getValue();

        //check recaptcha
        if (recaptcha.length === 0) {
            document.getElementById("recaptcha_error").innerHTML = Helper.EmptyErrorMessages.recaptcha;
            submitRef.current.removeAttribute("disabled");
            return false;
        }

        let formData = new FormData();
        formData.append('form', JSON.stringify(data));
        formData.append("recaptcha", recaptcha);
        formData.append("ticket", file);
        formData.append("action", "participate");

        fetch(process.env.REACT_APP_API_URL + '/index.php',
            {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(json => {

                if (json.success === true) {
                    window.scrollTo(0, 0);
                    props.setShowMerci(true);
                } else {

                    switch(json.message){
                        case  "#recaptcha" :
                            alert("Veuillez cocher la case \"Je ne suis pas un robot\" pour continuer.");
                            break;

                        case "#already_participate" :
                            alert("L'opération est limitée à une participation par personne.");
                            break;

                        default :
                            alert(json.message);
                    }
                    //setFormError(json.error);


                    submitRef.current.removeAttribute("disabled");
                    window.grecaptcha.reset();
                }

            });

    };

    return (
        <>
            <PageTitle title={"Complétez le formulaire"}/>
            <div id={"participeStrate1"} className={"strate"}>
                <Form id={"form_participe"} onSubmit={handleSubmit(onSubmit)} noValidate={true}>

                    <h2 className={"form_title"}><span>1.vos coordonnées</span><span>1/2</span></h2>

                    <CustomComboBox name="civilite" id="civilite" label={"Civilité*"} placeholder={"Sélectionner"}
                                    options={[
                                        [
                                            "Madame",
                                            "Madame"
                                        ],
                                        [
                                            "Monsieur",
                                            "Monsieur"
                                        ],
                                        [
                                            "Ne souhaite pas se prononcer",
                                            "Ne souhaite pas se prononcer"
                                        ]
                                    ]}
                                    register={register("civilite",{
                                        required:  Helper.EmptyErrorMessages.civilite
                                    })}
                                    error={errors.civilite && errors.civilite.message}/>

                    <CustomFieldText name="prenom" label="Prénom*" register={register('prenom', {
                        required: Helper.EmptyErrorMessages.prenom,
                        pattern: { value: Pattern.alphaPattern, message: Helper.InvalidErrorMessages.prenom },
                        minLength: { value: 2, message: Helper.InvalidErrorMessages.prenom }
                    })} error={errors.prenom && errors.prenom.message}/>
                    <CustomFieldText name="nom" label="Nom*" register={register('nom',{
                        required: Helper.EmptyErrorMessages.nom,
                        pattern: { value: Pattern.alphaPattern, message: Helper.InvalidErrorMessages.nom },
                        minLength: { value: 2, message: Helper.InvalidErrorMessages.nom }
                    })} error={errors.nom && errors.nom.message}/>

                    <BirthDayField
                        label={"Date de naissance<span>*</span>"}
                        register={register}
                        errors={errors}/>

                    <CustomFieldText name="mail" label="Adresse e-mail*"
                                     register={
                                         register("mail",{
                                             required: true,
                                             pattern : Pattern.emailPattern,
                                             validate: {
                                                 domain: (value) => Helper.checkMailDomain(value)
                                                 /*confirm : checkMailConfirm*/
                                             }
                                         })}
                                     error={[
                                         errors.mail && errors.mail.type === "required" && Helper.EmptyErrorMessages.mail,
                                         errors.mail && errors.mail.type === "pattern" && Helper.InvalidErrorMessages.mail,
                                         errors.mail && errors.mail.type === "domain" && Helper.InvalidErrorMessages.mail_domain,
                                         errors.mail && errors.mail.type === "confirm" && Helper.InvalidErrorMessages.mail_dif
                                     ]}/>

                    <CustomFieldText name="mail_confirm" label="Confirmation de l'adresse e-mail*"
                                     register={
                                         register("mail_confirm",{
                                             required: true,
                                             pattern : Pattern.emailPattern,
                                             validate: {
                                                 domain : (value) => Helper.checkMailDomain(value),
                                                 confirm: Helper.checkMailConfirm
                                             }
                                         })}
                                     error={[
                                         errors.mail_confirm && errors.mail_confirm.type === "required" && Helper.EmptyErrorMessages.mail,
                                         errors.mail_confirm && errors.mail_confirm.type === "pattern" && Helper.InvalidErrorMessages.mail,
                                         errors.mail_confirm && errors.mail_confirm.type === "domain" && Helper.InvalidErrorMessages.mail_domain,
                                         errors.mail_confirm && errors.mail_confirm.type === "confirm" && Helper.InvalidErrorMessages.mail_dif
                                     ]}/>

                    <h2 className={"form_title"}><span>2.votre achat</span><span>2/2</span></h2>

                    <CustomUploadField
                        label={"Veuillez télécharger une photo <br class='d-lg-block d-none'/>ou une copie <br class='d-lg-none'/>de votre preuve <br class='d-lg-block d-none'/>d’achat (ticket de caisse avec <br class='d-lg-block d-none'/>le nom du produit et la date d’achat)<span>*</span><div>Formats acceptés : jpeg, png, pdf,<br class='d-lg-block d-none'/> 7 Mo maximum</div>"}
                        name={"ticket"}
                        onchangeHandler={(e) => setFilename(e,"ticket",setFile)}
                        register={register("ticket",{required : Helper.EmptyErrorMessages.ticket})}
                        error={errors.ticket && errors.ticket && errors.ticket.message}
                    />

                    <div className={"form_row_wrapper radio_group"}>
                        <label>Privilégiez-vous les produits La Baleine <br className={"d-lg-none"}/>lorsque vous achetez du sel ? </label>
                        <div>
                            <input type={"radio"} name={"produit_baleine"} id={"produit_baleine_yes"} value={1} {...register("optin_produit_baleine")}/>
                            <label htmlFor={"produit_baleine_yes"}>oui</label>
                        </div>
                        <div>
                            <input type={"radio"} name={"produit_baleine"} id={"produit_baleine_non"} value={0} {...register("optin_produit_baleine")}/>
                            <label htmlFor={"produit_baleine_non"}>non</label>
                        </div>
                    </div>

                    <div className={"form_row_wrapper radio_group"}>
                        <label>
                            Le Bicarbonate La Baleine a des usages <br className={"d-lg-none"}/>
                            alimentaires mais également ménagers, <br className={"d-lg-none"}/>
                            le saviez-vous ?
                        </label>
                        <div>
                            <input type={"radio"} name={"bicarbonate_baleine"} id={"bicarbonate_baleine_yes"} value={1} {...register("optin_bicarbonate_baleine")}/>
                            <label htmlFor={"bicarbonate_baleine_yes"}>oui</label>
                        </div>
                        <div>
                            <input type={"radio"} name={"bicarbonate_baleine"} id={"bicarbonate_baleine_non"} value={0} {...register("optin_bicarbonate_baleine")}/>
                            <label htmlFor={"bicarbonate_baleine_non"}>non</label>
                        </div>
                    </div>

                    <div className={"optin_container"}>

                        <div id={"optin_reglement_wrapper"} className="checkbox_wrapper form_row_wrapper">
                            <input type="checkbox" name="optin_reglement" id="optin_reglement" {...register("optin_reglement",{required: Helper.EmptyErrorMessages.optin_reglement})}/>

                            <label htmlFor="optin_reglement">
                                Je reconnais avoir pris connaissance des <a href="/file/Termes%20et%20conditions.pdf" target={"_blank"}>Termes et conditions</a> de l’opération et les accepter<span>*</span>.
                            </label>
                            <div className="error text-center">{errors.optin_reglement && errors.optin_reglement.message}</div>
                        </div>

                        <div id={"optin_partenaire_wrapper"} className="checkbox_wrapper form_row_wrapper">
                            <input type="checkbox" name="optin_partenaire" id="optin_partenaire" {...register("optin_partenaire")}/>

                            <label htmlFor="optin_partenaire">
                                Oui, j'accepte de recevoir occasionnellement des informations sur les événements La Baleine – Compagnie des Salins du Midi et des Salines de l’Est. Les 90 ans de La Baleine vous réservent encore d'autres surprises !<sup>(1)</sup>
                            </label>
                        </div>

                        <div id={"optin_tlc_wrapper"} className="checkbox_wrapper form_row_wrapper">
                            <input type="checkbox" name="optin_tlc" id="optin_tlc" {...register("optin_tlc")}/>

                            <label htmlFor="optin_tlc">
                                Oui, je souhaite être contacté par TLC Marketing à des fins d'enquêtes de satisfaction, d'information sur nos offres promotionnelles et d'études de marché par e-mail.<sup>(2)</sup>
                            </label>
                        </div>

                    </div>

                    <div id={"rgpd_text_wrapper"}>
                        <p>Conformément à la loi Informatique et libertés du 6 janvier 1978 dans sa dernière version en vigueur ainsi que le Règlement UE 2016/679 du 27 avril 2016 publié au Journal Officiel de l’Union Européenne le 4 mai 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données, les informations recueillies sont destinées à TLC Marketing en sa qualité de responsable de traitement.</p>
                        <p>(1)La Baleine – Compagnie des Salins du Midi et des Salines de l’Est s’assure que les données recueillies sur le formulaire ne proviennent que de l’enregistrement volontaire par les visiteurs de leurs données nominatives. Elles sont destinées à un usage purement interne et ne sont accessibles qu’aux personnes responsables du traitement des demandes chez La Baleine – Compagnie des Salins du Midi et des Salines de l’Est. Elles ne font l’objet d’aucune communication, cession ou divulgation à des tiers. La Baleine – Compagnie des Salins du Midi et des Salines de l’Est conserve les données personnelles collectées sur le formulaire pendant une période de deux ans maximum, à compter de laquelle ces données sont totalement effacées, sauf consentement explicite du visiteur. Conformément à la loi modifiée n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, toute personne dispose d’un droit d’accès, de rectification, d’opposition et de suppression des données la concernant, pouvant être exercé à tout moment par courrier exclusivement adressé à : CSME, Services des données personnelles – La Baleine, Clichy-Pouchet (Bâtiment A) 92-98 boulevard Victor Hugo, 92115 Clichy.</p>
                        <p>(2)TLC Marketing prend les mesures conformes à l’état de l’art afin d’assurer la sécurité et la confidentialité des données, conformément aux dispositions légales précitées. Ces informations seront conservées jusqu’au 14/04/2028, au plus tard conformément à la réglementation si vous avez coché une des cases opt-in dans le formulaire de participation. Autrement vos données seront supprimées à partir du 14/07/2025. Vous bénéficiez d’un droit d’accès, de rectification, d’opposition, de limitation, d’effacement, de portabilité et d’information concernant vos données personnelles. Afin d’exercer ces droits, nous vous remercions de bien vouloir adresser votre demande auprès de TLC Marketing France par e-mail ou par courrier à l’adresse suivante : <a href={"mailto:francedata@tlcrewards.com?subject=Mes%20données%20personnelles"}>francedata@tlcrewards.com</a> TLC Marketing France – Mes données personnelles, 92 avenue de Wagram, 75017 Paris.</p>
                    </div>

                    <div className={"mandatory"}>
                        Les champs marqués d'une étoile (*) sont obligatoires.<br/>
                        Toute demande incomplète ne pourra être validée et traitée.
                    </div>

                {/* recaptcha */}
                <div className="text-center" id={"recaptcha_wrapper"}>
                    <ReCAPTCHA className="d-inline-block" sitekey="6LevKCQhAAAAAErcCcwZpbzQM4R_JcNDUrml4-2b" ref={recaptchaRef}/>
                    <div className="error text-center" id="recaptcha_error">&nbsp;</div>
                </div>

                {/* server error */}
                <div className="text-center">
                    <div className={"error text-center mb-3"} id={"global_error"}>
                        {/*error*/}
                    </div>
                    <input type={"submit"} className={"cta orange picto"} id={"submit_cta"} ref={submitRef} value={"JE PARTICIPE"}/>
                </div>


            </Form>
            </div>
            <Contact/>
        </>
    );
};

const JeParticipeMerci = () => {

    useEffect(()=> {
        window.mixpanelhandler.track("Page Loaded",{"Result count" : 1, "Page Name" : "Participation Confirmed"})
    },[])

    return (
        <>
            <PageTitle className={"big"} title={"merci !"}/>

            <div id={"bravoStrate1"} className={"strate"}>

                <p>Votre participation a bien été prise <br className={"d-lg-none"}/> en compte.</p>


                <p>
                    Si elle est valide, vous recevrez <strong>sous 7 jours <br className={"d-lg-none"}/>
                    ouvrés</strong> un e-mail avec votre code <br className={"d-lg-block d-none"}/>cadeau <br className={"d-lg-none"}/>
                    Académie du Goût et toutes les instructions <br className={"d-lg-none"}/>
                    pour en bénéficier.
                </p>

                <p>
                    Dans le cas contraire, si elle n’est pas valide, <br className={"d-lg-none"}/>
                    vous recevrez un e-mail vous en <br className={"d-lg-block d-none"}/>expliquant <br className={"d-lg-none"}/>
                    la raison.
                </p>

                <div id={"merci_warning"}>
                    N’oubliez pas de regarder <br className={"d-lg-none"}/>
                    dans vos courriers indésirables <br className={"d-lg-none"}/>
                    ou spams !
                </div>

                <div className={"line"}/>

                <div className={"subtitle"}>
                    <span>En attendant</span>
                    venez battre des records sur <br className={"d-lg-none"}/>l’un de nos jeux la baleine !
                </div>

                <GameCarousel/>

            </div>

            <Contact/>
        </>
    );
};

const JeParticipe = () => {

    const [showMerci, setShowMerci] = useState(false);

    return (
        <>
            {!showMerci && <JeParticipeForm setShowMerci={setShowMerci}/> }
            {showMerci && <JeParticipeMerci/>}
        </>
    );
};

export default JeParticipe;
