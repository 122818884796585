import React, {useRef, useState} from 'react';

const Contact = () => {

  return (
    <>
      <div id="contactStrate1" className={"strate bottom"}>
        <h2 className={"line"}>contactez-nous</h2>
        <h4>
          Vous avez des questions <br className={"d-lg-none"}/>
          ou besoin de plus d’informations ? <br/>
          Le Service Consommateurs de TLC Marketing <br className={"d-lg-none"}/>
          reste à votre disposition :
        </h4>

        <div id={"contact_item_wrapper"}>

          <div className={"contact_item"} id={"contact_item_phone"}>
            <div className={"content"}>
              <div className={"title"}>Par téléphone</div>
              <div className={"contact_link"}><a href={"tel:0974593976"}>09 74 59 39 76</a></div>
              <div className={"detail"}>
                Appel non surtaxé, prix d’un appel local, <br/>
                tarif en vigueur selon opérateur. <br/>
                Ligne active du lundi au vendredi, de 9h30 à 17h30, <br/>
                hors jours fériés.
              </div>
            </div>
          </div>

          <div className={"contact_item"} id={"contact_item_mail"}>
            <div className={"content"}>
              <div className={"title"}>Par e-mail</div>
              <div className={"contact_link"}>
                <a href={"mailto:labaleine-90ans@tlcrewards.com?subject=Op%C3%A9ration%C2%A0%22La%20Baleine%20f%C3%AAte%20ses%2090%20ans%22%20%2F%20Vos%20nom%20et%20pr%C3%A9nom"}>labaleine-90ans@tlcrewards.com</a>
              </div>
              <div className={"detail"}>
                Objet : Opération "La Baleine fête ses 90 ans" / <br/>Vos nom et prénom
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default Contact;
