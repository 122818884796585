import React, {useCallback, useEffect, useRef, createRef, useImperativeHandle, useState} from "react";
import {useSetState} from "react-use";

import {Modal} from "react-bootstrap";
import {Link} from "react-router-dom";

import {createObject, createObjectPX} from "./utils";

const ObstacleObject = React.forwardRef((props, ref) => {
    const {x, y, skin} = props;

   const objectStyle = {
        marginLeft: `${x}px`,
        /*transform: `translateY(${y}%)`*/
       marginTop :  `${y}px`
    };

    const topRef = createRef()
    const bottomRef = createRef()

    useImperativeHandle(ref, () => ({
        get bottom() {
            return bottomRef.current;
        },
        get top() {
            return topRef.current;
        }
    }));

    return(
        <div className={`obstacle_object ${skin}`} style={objectStyle}>
            <div className={"top"} ref={topRef}/>
            <div className={"bottom"} ref={bottomRef}/>
        </div>
    )
})

const Player = React.forwardRef(({bottom, rotation}, ref) => {

    const hitboxRef = createRef()
    const playerRef = createRef()

    const playerStyle = {
        bottom: `${bottom}px`,
        /*transform : `rotate(${rotation}deg)`*/
    };

    useImperativeHandle(ref, () => ({
        get hitbox() {
            return hitboxRef.current;
        },
        get player() {
            return playerRef.current;
        }
    }));

    return(
        <div id={"player"} style={playerStyle} ref={playerRef}>
            <div className={"hitbox"} ref={hitboxRef}/>
        </div>
    )
})

const GameUi = ({points}) => {

    return(
        <div id={"game_ui"}>
            <div className={"score"}>{points}</div>
        </div>
    )
}

const FlappyGame = () => {

    const scale = 1 / window.devicePixelRatio;

    //state
    const initialState = {
        objects: [],
        points : 0,
        isRunning : false,
        //spaceBetweenSpawn : 800 * scale,
        spaceBetweenSpawn : 600 * scale,
        spaceBetweenSpawnStep : 50 * scale,
        //speedSpawn : 2,
        speedSpawn : 3,
        playerIsDead : false,
        //player state
        y : 0,
        rotation : 0,
        fallSpeed: 0.25 * scale,
        jumpSpeed: -4 * scale,
        gravity: 0.15 * scale
    }

    const [gameState, setGameState] = useSetState(initialState)

    //Modal state
    const [showStartModal, setShowStartModal] = useState(true);
    const [showEndModal, setShowEndModal] = useState(false);

    //ref
    const requestRef = useRef();
    const gameRef = useRef();
    const playerRef = useRef();
    const obstacleRef = useRef();

    const toggleFullScreen = (state) => {
        if ("ontouchstart" in document.documentElement){
            let elem = document.getElementById("fullscreenHandler")

            if(state){
                if (document.exitFullscreen) {
                    elem.requestFullscreen()
                }

            }else{

                if (document.exitFullscreen && document.fullscreenElement !== null) {
                    document.exitFullscreen().catch((err) => console.error(err));
                }

            }
        }
    }

    //requestFrame
    const advanceStep = useCallback(() => {

        setGameState((oldState) => {

            if(oldState.playerIsDead){
                //player falling after death
                setPlayerPosition()

                //stop bg animation
                gameRef.current.classList.remove("animate")

                //wait for player to be out of screen
                if(isPlayerOutOfScreen(oldState.y)){
                    //stop game
                    setShowEndModal(true)
                    window.mixpanelhandler.track("Game completed",{"Score" : gameState.points, "Game Name" : "Underwater Obstacles"})

                    return {isRunning : false}
                }

                return;
            }

            //move object and detect hitbox
            const newObject = [];
            let newScore = oldState.points;

            for(let object of oldState.objects){

                const newX = object.x - oldState.speedSpawn;

                //object only exist in state and not in DOM
                if(object.ref.current === null) {
                    newObject.push({...object});
                    continue
                }

                //objet out of screen -> add point and remove
                if (Math.abs(newX) > (gameRef.current.offsetWidth + object.ref.current.top.offsetWidth) ) {
                    newScore++
                    continue
                }

                //check object hitbox
                const objTopBoundary = object.ref.current.top.getBoundingClientRect();
                const objBottomBoundary = object.ref.current.bottom.getBoundingClientRect();
                const playerBoundary = playerRef.current.hitbox.getBoundingClientRect();

                const hTopOverlap = (objTopBoundary.left <= playerBoundary.right && objTopBoundary.right >= playerBoundary.left)
                const vTopOverlap = (objTopBoundary.bottom >= playerBoundary.top && objTopBoundary.top <= playerBoundary.bottom)

                const hBottomOverlap = (objBottomBoundary.left <= playerBoundary.right && objBottomBoundary.right >= playerBoundary.left)
                const vBottomOverlap = (objBottomBoundary.bottom >= playerBoundary.top && objBottomBoundary.top <= playerBoundary.bottom)

                if( (hTopOverlap && vTopOverlap) || ( hBottomOverlap && vBottomOverlap ) || isPlayerOutOfScreen(oldState.y)){
                    return {
                        playerIsDead : true
                    }
                }

                //move object
                newObject.push({...object,x: newX,});
            }

            //move player
            setPlayerPosition()

            //check position of the last obstacle and create a new one if needeed
            if(oldState.objects.length){
                const obstacleSpace = Math.abs(oldState.objects[oldState.objects.length - 1].x)
                if(obstacleSpace >= oldState.spaceBetweenSpawn){
                    spawnObstacle()
                }
            }

            //calculate new game speed
            //const newSpaceBetweenSpawn = initialState.spaceBetweenSpawn - (Math.floor(newScore/10) * initialState.spaceBetweenSpawnStep);
            const newSpawnSpeed = initialState.speedSpawn * ( 1 + (newScore/25) )

            return {
                objects : newObject,
                points : newScore,
                speedSpawn : newSpawnSpeed
                //spaceBetweenSpawn : newSpaceBetweenSpawn
            }

        });

        requestRef.current = requestAnimationFrame(advanceStep);

    }, [gameState.objects]);

    //create random falling object
    const spawnObstacle = () => {
        const skin = ["tentacule","algues","submarine","recif"]
        const randSkin = Math.floor(Math.random() * skin.length)
        const obstacleSkin = skin[randSkin]

        const objRef = createRef()


        setGameState((oldState) => {
            //return { objects : [...oldState.objects, createObject(objRef, obstacleSkin)]}
            return { objects : [...oldState.objects, createObjectPX(objRef, obstacleSkin, obstacleRef.current.offsetHeight, gameRef.current.offsetHeight)]}
        })

    }

    //setPlayerPosition
    const setPlayerPosition = () => {

        setGameState((oldState) => {

            const position = oldState.y - oldState.fallSpeed;
            const speed = oldState.fallSpeed + oldState.gravity;

            //look up
            if(oldState.rotation < 30){
                oldState.rotation += 1
            }


            return({
                y : position,
                fallSpeed : speed
            })

        })
    }

    //jump on click
    const setPlayerJump = () => {

        setGameState((oldState) => {

            if(oldState.playerIsDead)
                return false;

            return {
                fallSpeed: oldState.jumpSpeed,
                rotation : -30
            }
        })
    }

    //handle key press
    const handlePlayerKeyDown = (e) => {

        e.preventDefault()

        if(e.which === 32)
            setPlayerJump()
    }

    //know if player is out of screen
    const isPlayerOutOfScreen = (playerPosition) => {

        const limite_basse = - playerRef.current.player.offsetHeight;
        const limite_haute = gameRef.current.offsetHeight + playerRef.current.player.offsetHeight;

        return playerPosition < limite_basse || playerPosition > limite_haute
    }

    //game loop
    useEffect(() => {

        const stop = () => {
            requestRef.current && cancelAnimationFrame(requestRef.current);
            toggleFullScreen(false)
        }

        if (gameState.isRunning) {
            spawnObstacle()
            gameRef.current.classList.add("animate")
            requestRef.current = requestAnimationFrame(advanceStep);

        } else {
            stop();
        }

        return () => stop();
    }, [gameState.isRunning])

    //onComponentDidMount
    useEffect(() => {

        if(typeof window.ontouchstart === 'undefined')
            document.addEventListener('click', setPlayerJump);
        else
            document.addEventListener('touchstart', setPlayerJump);

        document.addEventListener('keydown', handlePlayerKeyDown);

        //set player position in the middle of the screen
        setGameState({y : gameRef.current.offsetHeight / 2} )

        return ()=> {
            document.removeEventListener('click', setPlayerJump);
        }
    }, []);

    //start game
    const startGame = () => {

        //setfullScreen
        toggleFullScreen(true)

        setShowStartModal(false)
        setGameState({isRunning: true})
    }

    //reset game
    const resetGame = () => {

        window.mixpanelhandler.track("Replay button click",{"Result count" : 1, "Game Name" : "Underwater Obstacles"})

        setGameState({...initialState,y : gameRef.current.offsetHeight / 2})

        setShowEndModal(false);
        setShowStartModal(true);
    }

    /*useEffect(() => {
        //setfullScreen
        //alert(scale)

        setShowStartModal(false)
        setGameState({isRunning: true})
    },[])
*/
    return(
        <div id={"fullscreenHandler"}>

            <Modal show={showStartModal} onHide={() => setShowStartModal(false)} id={"modalStartGame_flappy"} centered={true} backdrop={"static"}>
                <Modal.Body>
                    <div className={"title"}>les obstacles sous-marins</div>
                    <div className={"description"}>
                        Allez le plus loin possible en évitant les obstacles ! <br/><br/>

                        <span className={"instruction mobile"}>
                            Tapez sur l’écran pour faire passer la Baleine entre les obstacles.
                        </span>

                        <span className={"instruction desktop"}>
                            Utilisez la barre d’espace ou cliquez sur l’écran pour faire passer<br/>la Baleine entre les obstacles.
                        </span>
                    </div>
                    <a href={"#0"} className={"cta orange picto"} onClick={startGame}>C'est parti !</a>
                    <div className={"mention"}>Jeu gratuit ne donnant droit à aucune dotation.</div>
                </Modal.Body>
            </Modal>
            <Modal show={showEndModal} onHide={() => setShowEndModal(false)} id={"modalEndGame_flappy"} centered={true} backdrop={"static"}>
                <Modal.Body>
                    <div className={"score"}>{gameState.points}<span>{gameState.points > 1 ? "PTS":"PT"}</span></div>
                    <a href={"#0"} id={"cta_reset"} className={"cta orange"} onClick={resetGame}>rejouer</a>
                    <Link to={"/academie-du-gout"} id={"cta_offer"} className={"cta orange picto"} onClick={() => window.mixpanelhandler.track("Offer button click",{"Result count" : 1, "Game Name" : "Underwater Obstacles" })}>découvrir l’offre</Link>
                </Modal.Body>
            </Modal>

            <div className={"mobile_landscape_switcher"}/>
            <div id={"game_flappy_wrapper"} className={"game_wrapper"} ref={gameRef}>
                <GameUi lives={gameState.lives} points={gameState.points}/>

                <div id={"mobile_game_control"}>
                    <div data-key={37} className={"left"}/>
                    <div data-key={39} className={"right"}/>
                </div>

                {gameState.objects.map((o, index) => {
                    return(
                        <ObstacleObject key={o.skin+"_"+index+"_"+o.y} x={o.x} y={o.y} ref={o.ref} skin={o.skin}/>
                    )
                })}
                <Player bottom={gameState.y} rotation={(gameState.rotation)} ref={playerRef}/>

                <div className={"obstacle_ref"} ref={obstacleRef}/>
            </div>
        </div>
    )
}

export default FlappyGame