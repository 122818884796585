import React from 'react';
import {Row, Col, Form} from 'react-bootstrap';
import * as Helper from "./form_inscription_helper";

//champs date de naissance
const BirthDayField = ({label, register, errors, onChange}) => {

  let day   = [];
  let month = [];
  let year  = [];

  for (let i = 1; i <= 31; i++) {
    if (i < 10) i = "0" + i;
    day.push(<option value={i} key={"day_" + i}>{i}</option>);
  }

  for (let i = 1; i <= 12; i++) {
    if (i < 10) i = "0" + i;
    month.push(<option value={i} key={"month_" + i}>{i}</option>);
  }

  for (let i = 2024; i >= 1900; i--)
    year.push(<option value={i} key={"year_" + i}>{i}</option>);


  const checkBirthDate = () => {

    let day   = document.getElementById("jour_naissance").value;
    let month = document.getElementById("mois_naissance").value;
    let year  = document.getElementById("annee_naissance").value;

    if (day.length !== 0 && month.length !== 0 && year.length !== 0) {

      let birthday = new Date(year, month - 1, day);

      let ageDifMs  = Date.now() - birthday.getTime();
      let ageDate   = new Date(ageDifMs);
      let diffYears = Math.abs(ageDate.getUTCFullYear() - 1970);

      if (diffYears < 18) {
        return false;
      }
    }

    return true;

  }

  return (
    <div className={"form_row_wrapper"}>

      <div className={"label_wrapper"}>
        <label dangerouslySetInnerHTML={{__html: label}}/>
      </div>

      <div className={"input_wrapper birthday_wrapper"}>
        <div>
          <select name="jour_naissance" id="jour_naissance" className={"default"} {...register("jour_naissance",{
            required : true,
            validate : checkBirthDate
          })} defaultValue={""}>
            <option disabled value={""}>jj</option>
            {day}
          </select>

          <select name="mois_naissance" id="mois_naissance" className={"default"} {...register("mois_naissance",{
            required : true,
            validate : checkBirthDate
          })} defaultValue={""}>
            <option disabled value={""}>mm</option>
            {month}
          </select>

          <select name="annee_naissance" id="annee_naissance" className={"default"} {...register("annee_naissance",{
            required : true,
            validate : checkBirthDate
          })} defaultValue={""}>
            <option disabled value={""}>aaaa</option>
            {year}
          </select>

        </div>

        <div className="error">
          {
              (errors.jour_naissance && errors.jour_naissance.type === "required" && Helper.EmptyErrorMessages.date_naissance) ||
              (errors.mois_naissance && errors.mois_naissance.type === "required" && Helper.EmptyErrorMessages.date_naissance) ||
              (errors.annee_naissance && errors.annee_naissance.type === "required" && Helper.EmptyErrorMessages.date_naissance)
          }
          {
            (errors.jour_naissance && errors.jour_naissance.type === "validate" && Helper.InvalidErrorMessages.date_naissance_age) ||
            (errors.mois_naissance && errors.mois_naissance.type === "validate" && Helper.InvalidErrorMessages.date_naissance_age) ||
            (errors.annee_naissance && errors.annee_naissance.type === "validate" && Helper.InvalidErrorMessages.date_naissance_age)
          }
        </div>
      </div>

    </div>
  );
};

export default BirthDayField;