import React from 'react';
import Contact from "./contact";
import PageTitle from "./_title";
import {Carousel} from "react-bootstrap";
import {Link} from "react-router-dom";

const Academie = () => {

    const createCarouselChief = () => {
        const content = [
            { title : "Anne-Sophie <span>PIC</span>" },
            { title : "Pierre <span>HERMÉ</span>" },
            { title : "Glenn <span>VIEL</span>" },
            { title : "Cédric <span>GROLET</span>" },
            { title : "Nina <span>MÉTAYER</span>" },
        ];

        let items = [];
        let items_desktop = [];
        content.forEach( (item, key) => {
            items.push(
                <Carousel.Item key={"ChiefItem" + key}>
                    <div className={`carousel_chief_item`} id={"carousel_chief_item" + (key + 1)}>
                        <div className={"picture"}/>
                        <div dangerouslySetInnerHTML={{__html : item.title}}/>
                    </div>
                </Carousel.Item>
            )

            items_desktop.push(
                <div className={`chief_item`} id={"chief_item" + (key + 1)}>
                    <div className={"picture"}/>
                    <div dangerouslySetInnerHTML={{__html : item.title}}/>
                </div>
            )
        })

        return (
            <>
                <div id={"chief_carousel_wrapper"} className={"d-lg-none"}>
                    <Carousel controls={true} interval={null} indicators={false}>
                        {items}
                    </Carousel>
                </div>

                <div id={"chief_wrapper"} className={"d-none d-lg-flex"}>
                    {items_desktop}
                </div>
            </>
        );
    }

    return(
        <>
            <PageTitle title={"Les cours de cuisine"}>
                <a href={"https://www.academiedugout.fr/"} target={"_blank"}/>
            </PageTitle>
            <div id="academieStrate1" className={"strate"}>

                <h2 className={"academie_title"}>
                    avec votre abonnement de 2 mois
                    <div>
                        <div>offert par la Baleine</div>
                    </div>
                </h2>

                <div id={"academie_step_wrapper"}>
                    <div className={"academie_step_item"}>
                        Accédez à <strong>plus de 8 000 <br/>
                        recettes</strong> de chefs pour <br/>
                        vous inspirer au <br className={"d-lg-none"}/>
                        quotidien.
                    </div>
                    <div className={"academie_step_item"}>
                        Découvrez en illimité de <br/>
                        nouvelles recettes de <br/>
                        cuisine et pâtisserie <br/>
                        de saison.
                    </div>
                    <div className={"academie_step_item"}>
                        Apprenez et <br/>
                        perfectionnez-vous grâce <br className={"d-lg-none"}/>
                        à <br className={"d-lg-block d-none"}/><strong>2 000 vidéos et <br className={"d-lg-none"}/>
                        pas-à-pas</strong> <br className={"d-lg-block d-none"}/>avec les grands <br className={"d-lg-none"}/>
                        noms de la <br className={"d-lg-block d-none"}/>gastronomie.
                    </div>
                    <div className={"academie_step_item"}>
                        Profitez du savoir-faire <br className={"d-lg-none"}/>
                        de <br className={"d-lg-block d-none"}/><strong>200 chefs, cheffes et <br/>
                        M.O.F</strong> qui vous livrent <br className={"d-lg-none"}/>
                        leurs <br className={"d-lg-block d-none"}/>secrets, tours de <br className={"d-lg-none"}/>
                        main <br className={"d-lg-block d-none"}/>et conseils.
                    </div>
                </div>

                <div className={"academie_details"}>
                    <p>
                        Élargissez vos horizons culinaires <br className={"d-lg-none"}/>
                        sans contraintes !
                    </p>
                    <p>
                        L' offre ne requiert pas de saisie d’informations <br className={"d-lg-none"}/>
                        bancaires. <br className={"d-none d-lg-block"}/> Si vous souhaitez bénéficier d’un nouvel <br className={"d-lg-none"}/>
                        abonnement, l’offre est annulable en ligne <br className={"d-lg-none"}/>
                        à tout moment.
                    </p>
                </div>

            </div>
            <div id="academieStrate2" className={"strate"}>

                <h2 className={"academie_title"}>
                    <div>
                        <div>quelques chefs de <br className={"d-lg-none"}/>l’académie du goût</div>
                    </div>
                </h2>

                {createCarouselChief()}

            </div>

            <div id={"academieStrate3"} className={"strate"}>

                <h2 className={"academie_title"}>
                    <div>
                        <div></div>
                    </div>
                </h2>

                <a href={"https://www.academiedugout.fr"} target={"_blank"} id={"cta_academie"}>
                    Pour en savoir plus, <br className={"d-lg-none"}/>rendez-vous sur l’Académie du Goût !
                    <span/>
                </a>

                <p>
                    L’Académie du Goût est accessible sur tous vos écrans.<br/>
                    Code cadeau à activer sous un délai de 3 mois à réception <br className={"d-lg-none"}/>sur le site <a href={"https://www.academiedugout.fr"} target={"_blank"}>l’Académie du Goût</a>.
                </p>

                <div className={"baleine_encart"}>
                    Découvrez également les délicieuses <br className={"d-lg-none"}/>
                    recettes de La Baleine <a href={"https://www.labaleine.fr/nos-recettes/"} target={"_blank"}>en cliquant ici</a> !
                </div>

                <div className={"text-center"}>
                    <Link to={"/je-participe"} className={"cta orange picto"}>PARTICIPEr à l’offre</Link>
                </div>

            </div>
            <Contact/>
        </>
    );
}

export default Academie;
