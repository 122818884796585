import React from 'react';
import Contact from "./contact";

const Attente = () => {

  return(
      <>
          <div id="strate1" className={"strate first"}>
              <h1>encore un peu <br className={"d-lg-none"}/>de patience les gourmets ...</h1>


              <p>L’opération "La Baleine fête ses 90 ans" <br className={"d-lg-none"}/>débutera <br className={"d-none d-lg-block"}/>le 1<sup>er</sup> janvier 2024 !</p>
              <p>À BIENTÔT !</p>

          </div>
      </>
  );
}

export default Attente;
