import React, {useCallback, useEffect, useRef, createRef, useImperativeHandle, useState} from "react";
import {useSetState} from "react-use";
import {createObject} from "./utils";

import {Modal} from "react-bootstrap";

import {
    SPEED_STEP,
    SPEED_BASE,
    SPAWN_INTERVAL_BASE,
    SPAWN_INTERVAL_MIN,
    SPAWN_INTERVAL_STEP,
    SPEED_MULTIPLICATOR_STEP,
    TRAP_SKIN,
    COIN_SKIN, SPAWN_CRAB_INTERVAL
} from "./constant";
import {Link} from "react-router-dom";


const FallingObject = React.forwardRef((props, ref) => {
    const {x, y, type, index, skin} = props;

    const objectStyle = {
        left: `${x}px`,
        top: `${y}px`
    };

    return( <div className={"falling_object "+type+" "+skin.class} id={"fo_"+index} style={objectStyle} ref={ref} data-value={skin.value}></div> )
})

const Crab = React.forwardRef( (props, ref) => {

    const hitboxRef = createRef()
    const crabRef = createRef()

    useImperativeHandle(ref, () => ({
        get hitbox() {
            return hitboxRef.current;
        },
        get crab() {
            return crabRef.current;
        }
    }));

    return(
        <div id={"crab"} className={""} ref={crabRef}>
            <div className={"hitbox"} ref={hitboxRef}/>
        </div>
    )
})

const Player = React.forwardRef((props, ref) => {

    const {x} = props;
    const hitboxRef = createRef()
    const playerRef = createRef()

    const playerStyle = {
        left: `${x}%`
    };

    useImperativeHandle(ref, () => ({
        get hitbox() {
            return hitboxRef.current;
        },
        get player() {
            return playerRef.current;
        }
    }));

    useEffect(() => {

        if(props.animation){
            playerRef.current.classList.add("animate")

            playerRef.current.onanimationend = function() {
                props.resetAnimation()
                this.onanimationend = null
                this.classList = ""
            }
        }

    },[props.animation])

    return(
        <div id={"player"} style={playerStyle} ref={playerRef} className={`${props.animation}`} direction={props.direction}>
            <div className={"hitbox"} ref={hitboxRef}/>
        </div>
    )
})

const GameUi = (props) => {

    const {points, lives} = props;

    return(
        <div id={"game_ui"}>
            <div className={"score"}>{points} point{points>1 && "s"}</div>
            <div className={"lives"} data-lives={lives}>
                <div/>
                <div/>
                <div/>
            </div>
        </div>
    )
}

const CoinCatcher = () => {

    //state
    const [gameState, setGameState] = useSetState({
        objects: [],
        points : 0,
        lives : 3,
        isRunning : false,
        playerPosition : 50,
        playerAnimation : "",
        speed : SPEED_BASE,
        spawnTimer : SPAWN_INTERVAL_BASE,
        keyPress : null,
        playerRotation : "right"
    })

    //Modal state
    const [showStartModal, setShowStartModal] = useState(true);
    const [showEndModal, setShowEndModal] = useState(false);

    //ref
    const requestRef = useRef();
    const gameRef = useRef();
    const intervalCrabRef = useRef();
    const playerRef = useRef();
    const crabRef = useRef();

    const toggleFullScreen = (state) => {

        if ("ontouchstart" in document.documentElement){
            let elem = document.getElementById("fullscreenHandler")

            if(state){
                if (document.exitFullscreen) {
                    elem.requestFullscreen()
                }

            }else{

                if (document.exitFullscreen) {
                    document.exitFullscreen().catch((err) => console.error(err));
                }

            }
        }

    }

    //requestFrame
    const advanceStep = useCallback(() => {

        setGameState((oldState) => {

            //move object and detect hitbox
            const newObject = [];
            for(let object of oldState.objects){

                const newY = object.y + object.speed;

                //object only exist in state and not in DOM
                //we ignore it
                if(object.ref.current === null) {
                    newObject.push({...object,y: newY});
                    continue
                }

                //objet out of screen -> remove
                if (newY > ( gameRef.current.offsetHeight + object.ref.current.offsetHeight))
                    continue

                //check falling object hitbox
                const objBoundary = object.ref.current.getBoundingClientRect();
                const playerBoundary = playerRef.current.hitbox.getBoundingClientRect();

                const hOverlap = (objBoundary.left <= playerBoundary.right && objBoundary.right >= playerBoundary.left)
                const vOverlap = (objBoundary.bottom >= playerBoundary.top && objBoundary.top <= playerBoundary.bottom)

                if( vOverlap && hOverlap ){

                    //get point or lose lives
                    if(object.type === "good"){
                        setPlayerPoint(oldState.points,object.skin.value)
                    }else{
                        //animate bad
                        handlePlayerLoseLive(oldState.lives)
                    }

                }else{
                    //move object
                    newObject.push({...object,y: newY,});
                }
            }

            //check crab hitbox
            const crabBoundary = crabRef.current.hitbox.getBoundingClientRect();
            const playerBoundary = playerRef.current.hitbox.getBoundingClientRect();

            const hCrabOverlap = (crabBoundary.left <= playerBoundary.right && crabBoundary.right >= playerBoundary.left)
            const vCrabOverlap = (crabBoundary.bottom >= playerBoundary.top && crabBoundary.top <= playerBoundary.bottom)

            if( vCrabOverlap && hCrabOverlap){
                //stop crabs
                handlePlayerLoseLive(oldState.lives)
                resetCrab()
            }

            //move player
            let playerPosition = oldState.playerPosition

            if(oldState.keyPress === "left" && playerPosition > 10){
                playerPosition -= 1
            }
            else if(oldState.keyPress === "right" && playerPosition < 90){
                playerPosition += 1
            }

            return {
                objects : newObject,
                playerPosition : playerPosition
            }

        });

        requestRef.current = requestAnimationFrame(advanceStep);

    }, [gameState.objects]);

    //create random falling object
    const spawnFallingObject = () => {
        //choose between coin and trap
        //1/3 -> trap
        //2/3 -> coin
        const rand = Math.floor(Math.random() * 30)
        let type = rand >= 20 ? "trap":"good"
        let skin = type === "good" ? COIN_SKIN : TRAP_SKIN

        const randSkin = Math.floor(Math.random() * skin.length)
        const objectSkin = skin[randSkin]

        const objRef = createRef()

        setGameState((oldState) => {

            if(oldState.isRunning)
                setTimeout(spawnFallingObject,oldState.spawnTimer)

            return { objects : [...oldState.objects, createObject(type, oldState.speed, objRef, objectSkin)]}
        })


    }

    //spawn crab
    const spawnCrab = () => {

        const rand = Math.floor(Math.random() * 2)
        const crabSide = rand ? "left":"right";

        crabRef.current.crab.classList.add(crabSide);
        crabRef.current.crab.classList.add("animate");

        crabRef.current.crab.onanimationend = resetCrab
    }

    const resetCrab = () => {
        crabRef.current.crab.className = ""
    }

    //return calculate object position
    const getObjectXPosition = (obj) => {

        const Box = gameRef.current.offsetWidth
        //const objBox = obj.ref.current.offsetWidth

        return ( Box * (obj.x / 100) ) - (20 / 2)
    }

    //move player
    const handlePlayerKeyDown = (e) => {
        let key = null

        //save key in state
        switch (e.which) {
            case 37: key = "left"; break;
            case 39: key = "right"; break;
        }

        setGameState({
            keyPress : key,
            playerRotation : key
        })
    }
    const handlePlayerKeyUp = () => {
        setGameState({keyPress : null})
    }

    //lose a live
    const handlePlayerLoseLive = (currentLives) => {
        const newLives = currentLives-1

        setGameState({
            lives : newLives,
            isRunning : newLives > 0,
            playerAnimation : "tilt"
        })

        if(newLives <= 0)
            setShowEndModal(true)
    }

    //getSpeed for current score
    const setPlayerPoint = (currentPoints, points) => {

        const newScore = currentPoints+points
        const speed_multiplicator = Math.floor(newScore/SPEED_MULTIPLICATOR_STEP)
        const speed = SPEED_BASE + (SPEED_STEP * speed_multiplicator)
        let spawnTime = SPAWN_INTERVAL_BASE - ( SPAWN_INTERVAL_STEP * speed_multiplicator )

        if(spawnTime < SPAWN_INTERVAL_MIN)
            spawnTime = SPAWN_INTERVAL_MIN

        setGameState({
            points : newScore,
            speed : speed,
            spawnTimer : spawnTime,
            playerAnimation : "blink point"+points
        })

    }

    //game loop
    useEffect(() => {

        const stop = () => {
            intervalCrabRef.current && clearInterval(intervalCrabRef.current);
            requestRef.current && cancelAnimationFrame(requestRef.current);

            toggleFullScreen(false)

            window.mixpanelhandler.track("Game completed",{"Score" : gameState.points, "Game Name" : "The race for gold coins"})
        }

        if (gameState.isRunning) {
            //intervalRef.current = setInterval(spawnFallingObject, gameState.spawnTimer);
            setTimeout(spawnFallingObject, gameState.spawnTimer);
            requestRef.current = requestAnimationFrame(advanceStep);

            //crab timer
            intervalCrabRef.current = setInterval(spawnCrab, SPAWN_CRAB_INTERVAL)
        } else {
            stop();
        }

        return () => stop();
    }, [gameState.isRunning])

    //player move
    useEffect(() => {

        document.addEventListener('keydown', handlePlayerKeyDown);
        document.addEventListener('keyup', handlePlayerKeyUp);

        document.querySelectorAll("#mobile_game_control > div").forEach((item) => {
            item.addEventListener('touchstart', function(e){

                handlePlayerKeyDown({which : parseInt(this.dataset.key)})
            })

            item.addEventListener('touchend', function(e){
                console.log("release")
                handlePlayerKeyUp()
            })

        });

        return ()=> {
            document.removeEventListener('keydown', handlePlayerKeyDown);
            document.removeEventListener('keyup', handlePlayerKeyUp);
        }
    }, []);

    //start game
    const startGame = () => {

        //setfullScreen
        toggleFullScreen(true)

        setShowStartModal(false)
        setGameState({isRunning: true})
    }

    //reset game
    const resetGame = () => {

        window.mixpanelhandler.track("Replay button click",{"Result count" : 1, "Game Name" : "The race for gold coins"})

        setGameState({
            objects: [],
            points : 0,
            lives : 3,
            isRunning : false,
            playerPosition : 50,
            playerAnimation : "",
            speed : SPEED_BASE,
            spawnTimer : SPAWN_INTERVAL_BASE,
            keyPress : null
        })

        setShowEndModal(false);
        setShowStartModal(true);
    }

    /*useEffect(() => {
        setGameState({isRunning: true})
    },[])*/

    return(
        <div id={"fullscreenHandler"}>
            <Modal show={showStartModal} onHide={() => setShowStartModal(false)} id={"modalStartGame_coincatcher"} centered={true} backdrop={"static"}>
                <Modal.Body>
                    <div className={"title"}>la course aux pièces d’or</div>
                    <div className={"description"}>
                        Attrapez le plus de pièces d’or !<br/>
                        Mais attention aux attaques de mouettes, <br className={"d-lg-none"}/>
                        aux ballons de plage et aux crabes sauteurs !<br/><br/>

                        <span className={"instruction mobile"}>
                            Pour déplacer la Baleine, <b>appuyez à gauche <br/>
                            ou à droite de l’écran</b> de votre smartphone <br/>
                            avec vos doigts.
                        </span>

                        <span className={"instruction desktop"}>
                            Déplacez la Baleine avec les touches gauche et droite de votre clavier.
                        </span>
                    </div>
                    <a href={"#0"} className={"cta orange picto"} onClick={startGame}>C'est parti !</a>
                    <div className={"mention"}>Jeu gratuit ne donnant droit à aucune dotation.</div>
                </Modal.Body>
            </Modal>

            <Modal show={showEndModal} onHide={() => setShowEndModal(false)} id={"modalEndGame_coincatcher"} centered={true} backdrop={"static"}>
                <Modal.Body>
                    <div className={"score"}>{gameState.points}<span>{gameState.points > 1 ? "PTS":"PT"}</span></div>
                    <a href={"#0"} id={"cta_reset"} className={"cta orange"} onClick={resetGame}>rejouer</a>
                    <Link to={"/academie-du-gout"} id={"cta_offer"} className={"cta orange picto"} onClick={() => window.mixpanelhandler.track("Offer button click",{"Result count" : 1, "Game Name" : "The race for gold coins" })}>découvrir l’offre</Link>
                </Modal.Body>
            </Modal>

            <div className={"mobile_landscape_switcher"}/>
            <div id={"game_coin_wrapper"} className={"game_wrapper"} ref={gameRef}>
                <GameUi lives={gameState.lives} points={gameState.points}/>
                <div id={"mobile_game_control"}>
                    <div data-key={37} className={"left"}/>
                    <div data-key={39} className={"right"}/>
                </div>

                {gameState.objects.map((o, index) => {
                    return(
                        <FallingObject x={getObjectXPosition(o)} y={o.y} index={index} type={o.type} ref={o.ref} skin={o.skin}/>
                    )
                })}
                <Crab ref={crabRef}/>
                <Player x={gameState.playerPosition} animation={gameState.playerAnimation} resetAnimation={ () => setGameState({playerAnimation : ""}) } direction={gameState.playerRotation} ref={playerRef}/>
            </div>
        </div>
    )
}

export default CoinCatcher