import React, {useEffect, useState} from 'react';
import {NavLink, Link} from "react-router-dom";

const HeaderMenu = (props) => {

  return(
      <>
      <div id="menu_overlay"/>
      <div id="menu">
        <nav>
          <a href={"#0"} id={"menu_close"} onClick={props.handleHideMenu} />
          {
            React.Children.map(props.children, (child) => {
              return React.cloneElement(child, { onClick: props.handleHideMenu })
            })
          }
        </nav>
      </div>
      </>
  )
}

const Header = () => {

  const [showMenu, setShowMenu] = useState(false)
  const handleShowMenu = () => setShowMenu(true)
  const handleHideMenu = () => setShowMenu(false)

  useEffect(()=> {

    if (showMenu) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

  },[showMenu])

  return(
    <header id="header" isopen={showMenu ? "1" : "0"}>
      <div id="header_wrapper">

        <div className="header_body" >
          <NavLink className="logo" to="https://www.labaleine.fr/la-baleine-a-90-ans/?utm_source=referral&utm_medium=banner&utm_campaign=tlc"/>
          <NavLink className="main_logo" to={"/"}/>
          <div id="burger" onClick={() => setShowMenu(!showMenu) }>
            <div/>
            <div/>
            <div/>
          </div>
        </div>

      </div>

      <HeaderMenu handleHideMenu={handleHideMenu}>
        <Link to="/">Accueil</Link>
        <Link to="/#comment_participer" smooth>Comment participer ?</Link>
        <Link to="/#homeStrate3" smooth>Jouer aux jeux</Link>
        <Link to="/je-participe">Participer à l'offre</Link>
        <Link to="/academie-du-gout">Les cours de cuisine</Link>

        <a href="/file/Produits%20éligibles.pdf" target={"_blank"}>Les produits éligibles</a>
        <Link to="/faq">FAQ</Link>
        <Link to="/#contactStrate1" smooth>Contact</Link>
      </HeaderMenu>

    </header>
  )
}

export default Header;

