const PageTitle = (props) => {

    return(
        <div className={`pageTitle strate first ${props.className}`}>
            <h1 className={"line"}>{props.title}</h1>
            {props.children}
        </div>
    )
}

export default PageTitle