import React from 'react';
import PageTitle from "./_title";
import Contact from "./contact";

const MentionsLegales = () => {
  return (
    <>
      <PageTitle title={"Informations légales"}></PageTitle>

      <div className={"strate"} id={"mlStrate1"}>

        <div id={"ml_wrapper"}>

          <div className={"block"}>
            <h4>Le site est édité et hébergé par :</h4>

            <p>
              TLC Marketing  France<br/>
              92 avenue de Wagram – 75017 Paris<br/>
              N° SIREN : 491 414 306<br/>
              N° SIRET : 491 414 306 00036<br/>
              APE : 7311Z<br/>
              RCS : Paris B 491 414 306<br/>
              SARL au capital social de : 150 000€<br/>
              Numéro de TVA intracommunautaire : <br className={"d-lg-none"}/>
              FR 70 491 414 306<br/>
              Adresse de courrier électronique : <br className={"d-lg-none"}/>
              <a href="mailto:bonjour@tlcmarketing.com" className={"underline"}>bonjour@tlcmarketing.com</a>
            </p>

          </div>
          <div className={"block"}>
            <h4>Pour le compte de :</h4>
            <p>
              Compagnie des Salins du Midi et des Salines de l’Est<br/>
              92-98 boulevard Victor Hugo – 92115 Clichy<br/>
              N° SIREN : 412 431 744<br/>
              N° SIRET : 412 431 744 00280<br/>
              APE : 0893Z<br/>
              RCS : Nanterre B 412 431 744<br/>
              SARL au capital social de : 68 040 000€<br/>
              Numéro de TVA intracommunautaire : <br className={"d-lg-none"}/>FR 07 412 431 744
            </p>
          </div>
          <div className={"block"}>
            <h4>Ce site est hébergé par la société :</h4>

            <p>
              Cloud Media<br/>
              6 rue du Général Clergerie – 75116 Paris<br/>
              N° SIREN : 752 470 971<br/>
              N° SIRET : 752 470 971 00025<br/>
              APE : 6201Z<br/>
              RCS : Paris B 752 470 971<br/>
              SAS au capital social de : 8 000€<br/>
              Numéro de TVA intracommunautaire : <br className={"d-lg-none"}/>
              FR 19 752 470 971
            </p>

          </div>

          <div className={"smallBlock"}>
            La conception, le design et le développement du site ont été effectués par les agences suivantes :
            TLC Marketing France et Cloud Media.
          </div>

        </div>
      </div>
      <Contact/>
    </>

  );
};

export default MentionsLegales;


