import {createBrowserRouter} from "react-router-dom";

import Layout from "../_Layout"
import ErrorPage from "../pages/404";

import Home from "../pages/home";
import Attente from "../pages/attente";
import MentionsLegales from "../pages/mentions-legales";
import Faq from "../pages/faq";
import Academie from "../pages/academie";
import JeParticipe from "../pages/je-participe";
import OperationTerminee from "../pages/operation-terminee";
import CoinCatcher from "../game/coin_catcher/CoinCatcher";
import {isOpeInProgressRedirect, isOpeStartRedirect} from "../loader/redirectLoader";
import SwimmingGame from "../game/plongeon/SwimmingGame";
import FlappyGame from "../game/flappy/FlappyGame";


const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout/>,
        errorElement: <ErrorPage />,
        children : [
            {
                path: "/",
                element : <Home/>,
                loader : isOpeStartRedirect
            },
            {
                path : "/attente",
                element : <Attente/>,
                //loader : isOpeInProgressRedirect
            },
            {
                path : "/informations-legales",
                element : <MentionsLegales/>,
                loader : isOpeStartRedirect
            },
            {
                path : "/faq",
                element : <Faq/>,
                loader : isOpeStartRedirect
            },
            {
                path : "/academie-du-gout",
                element : <Academie/>,
                loader : isOpeStartRedirect
            },
            {
                path : "/je-participe",
                element : <JeParticipe/>,
                loader : isOpeStartRedirect
            },
            {
                path : "/operation-terminee",
                element : <OperationTerminee/>,
                //loader : isOpeStartRedirect
            },
            {
                path : "/game/attrape-piece",
                element : <CoinCatcher/>
            },
            {
                path : "/game/plongeon-artistique",
                element : <SwimmingGame/>
            },
            {
                path : "/game/flappy-baleine",
                element : <FlappyGame/>
            }
        ]
    },
]);

export default router